import { FC } from 'react';
import IconProps from './IconProps';

const NumberListIcon: FC<IconProps> = (props) => {
  const { onClick, className } = props;
  const cursor = onClick ? 'cursor-pointer' : '';

  return (
    <svg onClick={onClick} className={`inline ${cursor} ${className}`} viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8.25 4.5H15.75" stroke="currentColor" strokeWidth="1.125" strokeLinecap="round" />
      <path d="M8.25 9H15.75" stroke="currentColor" strokeWidth="1.125" strokeLinecap="round" />
      <path d="M8.25 13.5H15.75" stroke="currentColor" strokeWidth="1.125" strokeLinecap="round" />
      <path
        d="M2.25 11.25H3.375C3.58409 11.25 3.68864 11.25 3.77558 11.2673C4.13261 11.3383 4.41169 11.6174 4.48271 11.9744C4.5 12.0614 4.5 12.1659 4.5 12.375C4.5 12.5841 4.5 12.6886 4.48271 12.7756C4.41169 13.1326 4.13261 13.4117 3.77558 13.4827C3.68864 13.5 3.58409 13.5 3.375 13.5C3.16591 13.5 3.06136 13.5 2.97442 13.5173C2.61739 13.5883 2.33831 13.8674 2.2673 14.2244C2.25 14.3114 2.25 14.4159 2.25 14.625V15.3C2.25 15.5121 2.25 15.6182 2.3159 15.6841C2.3818 15.75 2.48787 15.75 2.7 15.75H4.5"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M2.25 2.25H3.15C3.27427 2.25 3.375 2.35073 3.375 2.475V6.75M3.375 6.75H2.25M3.375 6.75H4.5"
        stroke="currentColor"
        strokeWidth="1.125"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default NumberListIcon;
